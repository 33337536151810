.title {
  margin: 0 10px;
  padding: 10px 20px;
  border: 0;
  font-family: inherit;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.title a {
  color: aliceblue;
  text-decoration: none;
  position: relative;
  font-weight: 100;
  transition: all 500ms ease;
}

.title a:hover,
.title a:active {
  font-weight: 1000;
  letter-spacing: 2px;
}

.title a:after {
  background-color: var(--pink);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  content: ".";
  color: transparent;
  height: 4px;
  width: 0%;
  transition: all 500ms ease;
  border-radius: 20px;
}

.title a:hover:after,
.title a:active:after {
  width: 100%;
}
