.navbar {
  width: 100%;
  height: 4rem;
  color: aliceblue;
  display: flex;
  justify-content: space-around;
  background-color: transparent;
  align-items: center;
  position: fixed;
  z-index: 50;
  animation: show-bar 700ms ease 500ms forwards;
  opacity: 0;
}

.navbar__logo {
  overflow: hidden;
  cursor: pointer;
}

.navbar__titles {
  display: none;
}

.navbar__mobile {
  margin-top: 10px;
}

@keyframes show-bar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 720px) {
  .navbar__titles {
    display: block;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 470px;
  }
  .navbar__mobile {
    display: none;
  }
}
