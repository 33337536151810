@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");
.logo {
  margin-top: -20px;
}

.logo text {
  font-family: var(--logo-font);
  letter-spacing: 3px;
  stroke: #ffa5d8;
  font-size: 1.7rem;
  font-weight: 700;
  stroke-width: 1;

  animation: textAnimate 5s infinite alternate;
}

@keyframes textAnimate {
  0% {
    stroke-dasharray: 0 50%;
    stroke-dashoffset: 20%;
    fill: var(--deep-purple);
  }

  100% {
    stroke-dasharray: 50% 0;
    stroke-dashoffstet: -20%;
    fill: hsla(189, 68%, 75%, 0%);
  }
}
