.main-page {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  max-height: auto;
  background-image: linear-gradient(
    180deg,
    var(--background) 50%,
    var(--light-purple) 85%
  );
  background-size: cover;
}

.main-page__image {
  border: 3px solid white;
  border-radius: 10px;
  width: 25%;
  animation: show-image 300ms ease-in 500ms forwards;
  opacity: 0;
  cursor: pointer;
}

.main-page__image img:hover {
  animation: move-pic-back 300ms ease;
}

.main-page__image img {
  object-fit: cover;
  position: relative;
  animation: movePic 700ms ease 500ms forwards;
  border-radius: 10px;
  margin-bottom: -8px;
}

.main-page__text {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: aliceblue;
  animation: moveText 700ms ease 500ms forwards;
  opacity: 0;
  margin: 30px 0;
}

.main-page__text h1 {
  font-family: var(--name-font);
  letter-spacing: 3px;
  color: #c899a9;
  font-weight: 700;
  font-size: 3.7rem;
  margin: 0;
}

.main-page__text h1 a {
  cursor: pointer;
}

.main-page__text h1 a:hover,
.main-page__text h1 a:active {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='rgb(255, 238, 220)' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 20%;
  border-bottom: 0;
  text-decoration: none;
  color: #c899a9;
}

.main-page__text p {
  font-size: 1.4rem;
}

@keyframes movePic {
  0% {
    left: 0;
    top: 0;
  }
  100% {
    left: -30px;
    top: -30px;
  }
}

@keyframes move-pic-back {
  0% {
    left: -30px;
    top: -30px;
  }
  100% {
    left: 0;
    top: 0;
  }
}

@keyframes show-image {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes moveText {
  0% {
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 900px) {
  .main-page {
    flex-direction: column;
    justify-content: center;
  }

  .main-page__image {
    margin-top: 6.5rem;
    width: 50%;
    /* margin-top: 100px; */
  }

  .main-page__text {
    align-items: center;
    text-align: center;
    width: 80%;
  }

  .main-page__text h1 {
    font-size: 2.5rem;
  }
}
