.side-menu__backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.side-menu__show {
  width: 50%;
  height: 100%;
  position: fixed;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  animation: menuBar 300ms ease-in;
  z-index: 1001;
}

.side-menu__container {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes menuBar {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@media (min-width: 720px) {
  .side-menu__backdrop {
    display: none;
  }

  .side-menu__show {
    display: none;
  }

  .side-menu__container {
    display: none;
  }
}
