.contact {
  display: grid;
  grid-template-rows: 30px 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "web web" "img contact";
  width: 75%;
  height: auto;
  background-color: var(--projects);
  margin: auto;
  border-radius: 50px;
  justify-items: center;
  align-items: center;
  margin-top: 30%;
  border: 2px var(--grey) solid;
  overflow: hidden;
}

.contact__web {
  width: 100%;
  grid-area: web;
  justify-self: start;
  border-bottom: 2px var(--grey) solid;
}

.contact__web--dot {
  margin-left: 20px;
  height: 12px;
  width: 12px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  display: inline-block;
}

.contact__info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  grid-area: contact;
  /* padding: 20px; */
}

.contact__info h1 {
  margin: 10px 20px;
  align-self: flex-start;
  color: #493041;
}

.contact__info p {
  margin: 0 20px;
  font-weight: 900;
  color: var(--background);
  font-size: 1.4rem;
}

.contact__info--icons {
  margin: 30px auto;
}

.contact__info--icons a {
  margin: 0 10px;
}

.contact__me {
  grid-area: img;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact__avatar {
  width: 70%;
  height: auto;
  opacity: 0.6;
  padding: 20px;
}

.contact__avatar:hover {
  animation: moveUp2 800ms ease-in-out forwards;
}

.contact__info--icons a img:hover,
.contact__info--icons a img:active {
  animation: moveUp 800ms ease-in-out forwards;
}

.contact__email {
  display: flex;
  justify-content: center;
}

.contact__email a {
  padding-left: 10px;
  display: inline-block;
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(-10%);
  }
}

@keyframes moveUp2 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(-2%);
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .contact {
    display: grid;
    margin-top: 56%;
  }

  .contact__email a {
    font-size: 0.8rem;
  }
}

@media (max-width: 800px) {
  .contact {
    display: grid;
    grid-template-rows: 30px auto auto;
    grid-template-columns: 1fr;
    grid-template-areas: "web" "img" "contact";
    margin-top: 70%;
    row-gap: 30px;
  }

  .contact__email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact__email a {
    font-size: 0.8rem;
  }
}
