.menu-icon {
  cursor: pointer;
  padding: 10px;
  border-radius: 0px;
  transition: all 500ms ease;
}

.menu-icon:hover {
  fill: var(--pink);
  border: 3px solid var(--pink);
  border-radius: 50px;
}
