@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Caveat:wght@500;700&family=Comforter+Brush&display=swap");

:root {
  --deep-purple: #897da5;
  --pink: #caabb8;
  --light-purple: #a999b3;
  --super-light: #d3c8db;
  --skills: #92859f;
  --background: #3f3f3f;
  --projects: rgba(197, 197, 197, 0.1);
  --grey: rgba(0, 0, 0, 0.2);
  --main-font: "Architects Daughter", cursive;
  --big-title: "Comforter Brush", cursive;
  --name-font: "Caveat", cursive;
  --logo-font: "Permanent Marker", cursive;
}

* {
  box-sizing: border-box;
}

html {
  font-family: var(--main-font);
}

body {
  margin: 0;
  background: var(--light-purple);
}

::-webkit-scrollbar {
  width: 0px;
}

::selection {
  background: var(--deep-purple); /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: var(--deep-purple); /* Gecko Browsers */
}
