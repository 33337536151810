.proj {
  position: relative;
}

/* big illustrations */
.proj__lamp {
  z-index: -10;
}

.proj__lamp img {
  width: 20%;
}

.proj__wfh {
  z-index: -10;
}

.proj__wfh img {
  position: absolute;
  width: 20%;
  right: 5%;
  bottom: 0%;
}
/*  */

/* main */
.proj__titles {
  height: auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.8;
}

.proj__titles img {
  transform: rotate(-5deg);
  margin-top: -25px;
  width: 16%;
}

.proj__items {
  z-index: -9;
}

/* small illustations */
.proj__illu {
  z-index: -11;
  opacity: 0.7;
}

.proj__call img {
  position: absolute;
  left: 3%;
  bottom: 13%;
}

.proj__video img {
  position: absolute;
  right: 5%;
  top: 20%;
}

.proj__cicle img {
  position: absolute;
  right: 20%;
  top: 50%;
}

.proj__tri img {
  position: absolute;
  right: 15%;
  top: 10%;
}

.proj__tri2 img {
  position: absolute;
  left: 10%;
  bottom: -10%;
  transform: rotate(-50deg);
}

.proj__line2 img {
  position: absolute;
  left: 15%;
  top: 10%;
}

.proj__line3 img {
  transform: rotate(-50deg);
  position: absolute;
  left: 50%;
  bottom: 0;
}

.proj__ret img {
  position: absolute;
  left: 30%;
  bottom: -20%;
}

@media (max-width: 500px) {
  .proj__lamp img {
    width: 40%;
  }

  .proj__wfh img {
    width: 40%;
  }

  .proj__pics {
    width: 70%;
  }

  .proj__titles img {
    width: 50%;
  }
}
